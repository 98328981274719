import { graphql } from 'gatsby'
import React, { useEffect } from 'react';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

export default function Template({ data }) {
  const { justARandomAlias: post } = data;

  useEffect(() => {
    deckDeckGoHighlightElement();
  });

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <div style={{ marginBottom: `1.45rem` }}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
};

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    justARandomAlias: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
